.square-loader div {
  animation: square-loader 1.2s linear infinite;
}
.square-loader div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.square-loader div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.square-loader div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.square-loader div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.square-loader div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.square-loader div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.square-loader div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.square-loader div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.square-loader div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes square-loader {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Dot Spinner */

.dot-spinner-primary {
  background: radial-gradient(farthest-side, var(--clr-primary) 95%, #0000) 50%
      0/12px 12px no-repeat,
    radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.5px),
        var(--clr-primary) calc(100% - 2.5px)
      )
      content-box;
  animation: dotspinner 2s infinite;
}

.dot-spinner-light {
  background: radial-gradient(farthest-side, rgb(235, 235, 235) 95%, #0000) 50%
      0/12px 12px no-repeat,
    radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.5px),
        white calc(100% - 2.5px)
      )
      content-box;
  animation: dotspinner 2s infinite;
}

.dot-spinner-light.sm {
  background: radial-gradient(farthest-side, rgb(235, 235, 235) 95%, #0000) 33%
      0/9px 9px no-repeat,
    radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.5px),
        white calc(100% - 2.5px)
      )
      content-box;
}

@keyframes dotspinner {
  to {
    transform: rotate(1turn);
  }
}
